.app-fallback {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-fallback .ant-spin-text {
  margin-top: 15px;
}

.flex {
  display: flex;
}
